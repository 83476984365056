<template>
  <div>
    <h2 class="mb-4">{{ $t('ticketing.command.summary') }}</h2>

    <div v-if="ticket" class="price-table">
      <div class="price-table-row mt-2 mb-4">
        <div class="name price-table-col-1">{{ ticket.name }}</div>
        <div class="price-table-col-2">
          <h2 class="price alternate-font-family">
            {{ ticket.price | currency(ticket.currency) }}
          </h2>
        </div>
      </div>
      <hr />
      <div class="price-table-row my-4">
        <div class="price-table-col-1">{{ $t('ticketing.command.subTotal') }}</div>
        <div class="price-table-col-2">
          <h2 class="sub-price alternate-font-family">
            {{ ticket.price | currency(ticket.currency) }}
          </h2>
        </div>
      </div>
      <div class="price-table-row my-4">
        <div class="price-table-col-1">{{ $t('ticketing.command.qst') }}</div>
        <div class="price-table-col-2">
          <h2 class="qst alternate-font-family">{{ ticket.qst | currency(ticket.currency) }}</h2>
        </div>
      </div>
      <div class="price-table-row my-4">
        <div class="price-table-col-1">{{ $t('ticketing.command.gst') }}</div>
        <div class="price-table-col-2">
          <h2 class="gst alternate-font-family">{{ ticket.gst | currency(ticket.currency) }}</h2>
        </div>
      </div>
      <hr />
      <div class="price-table-row my-4">
        <div class="price-table-col-1">
          <strong>{{ $t('ticketing.command.total') }}</strong>
        </div>
        <div class="price-table-col-2">
          <h1 class="total alternate-font-family">
            {{ ticket.total | currency(ticket.currency) }}
          </h1>
        </div>
      </div>
    </div>
    <div class="error--text">{{ error }}</div>

    <div class="checks">
      <v-checkbox
        v-if="false"
        :input-value="newsletter"
        name="newsletter"
        :label="$t('ticketing.newsletter', { eventName })"
        @change="$emit('newsletterChange', $event)"
      >
      </v-checkbox>

      <v-checkbox
        :input-value="receiveInfo"
        name="receiveInfo"
        :label="$t('ticketing.receiveInfo', { eventName })"
        @change="$emit('receiveInfoChange', $event)"
      >
      </v-checkbox>
    </div>

    <div class="actions mt-4 mb-16">
      <v-btn
        class="secondary-btn"
        color="secondary"
        outlined
        tile
        x-large
        :loading="isLoading"
        @click.native="$emit('pay-command')"
        >{{ $t('ticketing.command.payment') }}</v-btn
      >
    </div>
  </div>
</template>

<script>
export default {
  name: 'CommandSummary',
  props: {
    ticket: {
      type: Object,
      required: false,
    },
    eventName: {
      type: String,
      required: true,
    },
    newsletter: {
      type: Boolean,
      required: false,
      default: false,
    },
    receiveInfo: {
      type: Boolean,
      required: false,
      default: false,
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    error: {
      type: String,
      required: false,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
.price-table {
  hr {
    border: 1px solid var(--v-primary-base);
  }

  .price-table-row {
    display: flex;
    align-items: flex-end;

    .price-table-col-1 {
      width: 70%;
      font-size: 1.5rem;
    }

    .price-table-col-2 {
      width: 30%;
      text-align: end;
    }
  }
}

.actions {
  display: flex;
  justify-content: center;
}
</style>
