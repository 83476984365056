<template>
  <div id="confirmation">
    <thank-you v-if="payment && payment.id" :confirmation="payment.id" @visit-event="visitEvent" />

    <loading v-else />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import store from '@/stores';
import Loading from '@/components/loading/Loading.vue';
import ThankYou from '@/components/ticketing/thank-you/ThankYou.vue';
import { APP_TICKET_MODULE } from '@/stores/umanize-app/actions/ticket/app-ticket.actions';

export default {
  name: 'Confirmation',
  components: {
    Loading,
    ThankYou,
  },
  computed: {
    ...mapGetters(APP_TICKET_MODULE, ['payment']),
  },
  methods: {
    visitEvent() {
      const { eventId } = this.$route.params;
      this.$router.push(`/events/${eventId}/lobby`);
    },
  },
  beforeRouteEnter(to, from, next) {
    const payment = store.getters[`${APP_TICKET_MODULE}/payment`];
    if (!payment) {
      next('/dashboard');
      return;
    }
    next();
  },
};
</script>

<style lang="scss" scoped>
#confirmation {
  height: 75vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
