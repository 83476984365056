<template>
  <div id="thank-you">
    <h1>{{ $t('ticketing.thankYou.message') }}</h1>

    <div class="confirmation-number mt-12">
      <h2 class="pr-8">{{ $t('ticketing.thankYou.confirmationNumber') }}</h2>
      <p class="confirmation">{{ confirmation }}</p>
    </div>

    <v-btn
      class="secondary-btn"
      color="secondary"
      tile
      outlined
      x-large
      @click.native="$emit('visit-event')"
      >{{ $t('ticketing.thankYou.visitEvent') }}</v-btn
    >
  </div>
</template>

<script>
export default {
  name: 'ThankYou',
  props: ['confirmation'],
};
</script>

<style lang="scss" scoped>
@import '../../../styles/core/variables';

#thank-you {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  .confirmation-number {
    display: flex;
    flex-direction: column;
    align-items: center;

    p {
      font-size: 1.5rem;
    }
  }
}
</style>
