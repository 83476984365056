/* eslint-disable @typescript-eslint/no-explicit-any */
import store from '@/stores';
import { AppEvent } from '@/models';
import { paymentStatus } from '@/models/ticketing/payment.model';
import { SHARED_AUTH_MODULE } from '@/stores/shared/actions/auth/auth.actions';
import { toDashboard } from '@/navigation';
import { APP_EVENT_MODULE } from '@/stores/umanize-app/actions/event/app-event.actions';
import {
  APP_TICKET_MODULE,
  PAYMENT_INTENT,
} from '@/stores/umanize-app/actions/ticket/app-ticket.actions';
import { FETCH_MY_EVENTS } from '@/stores/agnostic/actions/event/agnostic-event.actions';

export const preProcessPaymentMixin = {
  async beforeRouteEnter(to, _, next) {
    const isLoggedIn = store.getters[`${SHARED_AUTH_MODULE}/isLoggedIn`];
    if (isLoggedIn) {
      // We get the list of user events: if he already has access to the event
      // we can forward him to the eventś dashboard
      await store.dispatch(`${APP_EVENT_MODULE}/${FETCH_MY_EVENTS}`);
      const myEvents = store.getters[`${APP_EVENT_MODULE}/myEvents`];

      const event = myEvents.find((evt: AppEvent) => evt.id === to.params.eventId);
      if (event) {
        next(toDashboard());
        return;
      }

      const ticket = store.getters[`${APP_TICKET_MODULE}/selectedTicket`];
      const { eventId } = to.params;

      if (!ticket) {
        next(`/events/${eventId}/buy`);
        return;
      }

      // The user has a ticket: we're going to create the payment intent.
      // If the intent is immediately successful (for example: a $0.00 ticket),
      // we're forwarding him to the confirmation.
      //
      // If not, we can go on with the payment
      try {
        const payment = await store.dispatch(`${APP_TICKET_MODULE}/${PAYMENT_INTENT}`, ticket);
        if (payment.status === paymentStatus.SUCCEEDED) {
          next(`/events/${eventId}/confirmation`);
          return;
        }
        next((vm) => {
          vm.setPayment(payment);
          next();
        });
        return;
      } catch (e) {
        // If we get a 409, it means the user already has a ticket for this event
        // so we can forward him to the dashboard
        if (e.status === 409) {
          next(toDashboard());
          return;
        }

        console.error(e);
      }
    }

    next();
  },
};
