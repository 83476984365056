<template>
  <div id="process-payment">
    <payment
      v-if="selectedTicket && payment"
      :error-message="errorMessage"
      :error-type="errorType"
      :is-loading="isSending"
      @pay="pay"
    />
    <loading v-else />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Payment from '@/components/ticketing/payment/Payment.vue';
import Loading from '@/components/loading/Loading.vue';
import { DISPLAY_MESSAGE, MESSAGE_MODULE } from '@/stores/shared/actions/message/message.actions';
import { APP_EVENT_MODULE } from '@/stores/umanize-app/actions/event/app-event.actions';
import { FETCH_MY_EVENTS } from '@/stores/agnostic/actions/event/agnostic-event.actions';
import {
  APP_TICKET_MODULE,
  PAY,
  PAYMENT_INTENT,
} from '@/stores/umanize-app/actions/ticket/app-ticket.actions';
import { preProcessPaymentMixin } from './mixins/preprocess.payment.mixin';

export default {
  name: 'ProcessPayment',
  data() {
    return {
      payment: null,
    };
  },
  components: {
    Payment,
    Loading,
  },
  mixins: [preProcessPaymentMixin],
  computed: {
    ...mapGetters(APP_TICKET_MODULE, [
      'selectedTicket',
      'payment',
      'isSending',
      'errorMessage',
      'errorType',
    ]),
    ...mapGetters(APP_EVENT_MODULE, ['myEvents']),
  },
  methods: {
    ...mapActions(MESSAGE_MODULE, [DISPLAY_MESSAGE]),
    ...mapActions(APP_EVENT_MODULE, [FETCH_MY_EVENTS]),
    ...mapActions(APP_TICKET_MODULE, [PAYMENT_INTENT, PAY]),
    setPayment(payment) {
      this.payment = payment;
    },
    async pay(paymentForm) {
      try {
        await this[PAY](paymentForm);
        const { eventId } = this.$router.currentRoute.params;
        await this.$router.push(`/events/${eventId}/confirmation`);
      } catch (err) {
        // The error is managed using the tickets module error state
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#process-payment {
  height: 100%;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: 1rem;
}
</style>
