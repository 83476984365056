<template>
  <div id="buy-ticket" v-if="event">
    <v-container class="px-0 py-0" fluid>
      <v-row no-gutters>
        <v-col cols="1" lg="1" />

        <v-col class="px-lg-16" cols="10" lg="6">
          <div v-if="eventIsLoaded">
            <event-info :event="event" />

            <div v-if="isLoaded">
              <div v-if="hasMoreThanOneTicket">
                <select-ticket :tickets="tickets" @select-ticket="setSelectedTicket" />
              </div>

              <p v-else>{{ $t('ticketing.noTicket') }}</p>
            </div>

            <loading v-else />
          </div>

          <loading v-else />
        </v-col>

        <v-col cols="1" lg="5">
          <event-images-carousel :event="event" />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import { SHARED_AUTH_MODULE } from '@/stores/shared/actions/auth/auth.actions';
import { DISPLAY_MESSAGE, MESSAGE_MODULE } from '@/stores/shared/actions/message/message.actions';
import EventInfo from '@/components/ticketing/event-info/EventInfo.vue';
import EventImagesCarousel from '@/components/ticketing/event-images-carousel/EventImagesCarousel.vue';
import Loading from '@/components/loading/Loading.vue';
import SelectTicket from '@/components/ticketing/select-ticket/SelectTicket.vue';
import { APP_EVENT_MODULE } from '@/stores/umanize-app/actions/event/app-event.actions';
import { APP_USER_MODULE } from '@/stores/umanize-app/actions/user/app-user.actions';
import {
  APP_TICKET_MODULE,
  SET_TICKET_NEWSLETTER,
  SET_TICKET_RECEIVE_INFO,
} from '@/stores/umanize-app/actions/ticket/app-ticket.actions';
import { FETCH_MY_EVENTS } from '@/stores/agnostic/actions/event/agnostic-event.actions';
import {
  GET_TICKETS,
  SELECT_TICKET,
} from '@/stores/agnostic/actions/ticket/agnostic-ticket.actions';
import { buyMixin } from './mixins/buy.mixin';

export default {
  name: 'BuyTicket',
  components: {
    Loading,
    EventInfo,
    SelectTicket,
    EventImagesCarousel,
  },
  mixins: [buyMixin],
  computed: {
    ...mapGetters(SHARED_AUTH_MODULE, ['isLoggedIn']),
    ...mapGetters(APP_EVENT_MODULE, ['event', 'myEvents', 'eventIsLoaded']),
    ...mapGetters(APP_USER_MODULE, ['loggedInUser']),
    ...mapGetters(APP_TICKET_MODULE, [
      'newsletter',
      'receiveInfo',
      'tickets',
      'selectedTicket',
      'isSending',
      'isLoaded',
      'errorMessage',
    ]),
    hasMoreThanOneTicket() {
      return this.tickets.length > 1;
    },
    hasOneTicket() {
      return (this.tickets || []).length === 1;
    },
  },
  methods: {
    ...mapActions(APP_EVENT_MODULE, [FETCH_MY_EVENTS]),
    ...mapActions(APP_TICKET_MODULE, [
      GET_TICKETS,
      SELECT_TICKET,
      SET_TICKET_NEWSLETTER,
      SET_TICKET_RECEIVE_INFO,
    ]),
    ...mapActions(MESSAGE_MODULE, [DISPLAY_MESSAGE]),
    async getTickets(id) {
      return this[GET_TICKETS](id);
    },
    setSelectedTicket(ticket) {
      this[SELECT_TICKET](ticket);
      const { id } = this.event;
      this.$router.push(`/events/${id}/summary`).catch((failure) => {
        console.debug(failure);
      });
    },
  },
  async mounted() {
    const tickets = await this.getTickets(this.$route.params.eventId);
    if (tickets && tickets.length === 1) {
      this.$router.replace(`/events/${this.event.id}/summary`).catch((failure) => {
        console.debug(failure);
      });
    }
  },
};
</script>

<style lang="scss" scoped>
@import 'src/styles/core/variables';

#buy-ticket {
  width: 100%;
  height: 100%;

  .container,
  .row {
    height: 100%;
  }

  img {
    display: none;
  }
}

@include carousel-fix(contain);

@include breakpoint(medium) {
  #buy-ticket {
    img {
      display: inline;
      width: 100%;
      height: 100%;
    }
  }
}
</style>
