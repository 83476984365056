import store from '@/stores';
import { AppEvent, MessageType } from '@/models';
import { SHARED_AUTH_MODULE } from '@/stores/shared/actions/auth/auth.actions';
import { DISPLAY_MESSAGE, MESSAGE_MODULE } from '@/stores/shared/actions/message/message.actions';
import { toDashboard } from '@/navigation';
import { APP_EVENT_MODULE } from '@/stores/umanize-app/actions/event/app-event.actions';
import { FETCH_MY_EVENTS } from '@/stores/agnostic/actions/event/agnostic-event.actions';

export const buyMixin = {
  async beforeRouteEnter(to, _, next) {
    const isLoggedIn = store.getters[`${SHARED_AUTH_MODULE}/isLoggedIn`];
    if (isLoggedIn) {
      await store.dispatch(`${APP_EVENT_MODULE}/${FETCH_MY_EVENTS}`);
      const myEvents = store.getters[`${APP_EVENT_MODULE}/myEvents`];
      const event = myEvents.find((event: AppEvent) => event.id === to.params.eventId);

      if (event) {
        next((vm) => {
          store.dispatch(`${MESSAGE_MODULE}/${DISPLAY_MESSAGE}`, {
            type: MessageType.info,
            text: vm.$t('ticketing.info.already-bought'),
          });

          next(toDashboard());
        });
        return;
      }
    }
    next();
  },
};
