<template>
  <div>
    <div class="select-ticket">
      <h2 class="mb-6">{{ $t('ticketing.selectTicket') }}</h2>

      <div class="mb-12" v-for="(ticket, index) in tickets" :key="index">
        <div class="ticket-row">
          <v-btn
            class="secondary-btn ticket-row__button"
            color="secondary"
            outlined
            tile
            x-large
            :disabled="disabled"
            @click.native="$emit('select-ticket', ticket)"
            >{{ ticket.name }}</v-btn
          >

          <div class="ticket-row__price">
            <h2 class="mb-0">
              <strong class="alternate-font-family">
                {{ ticket.price | currency(ticket.currency) }}</strong
              >
            </h2>
            <p class="mb-0">
              <small>{{ $t('ticketing.taxesAndInclusions') }}</small>
            </p>
          </div>
        </div>

        <p class="mt-4">
          <small>{{ ticket.description }}</small>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SelectTicket',
  props: {
    tickets: {
      type: Array,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.ticket-row {
  display: flex;
  align-items: center;

  &__button {
    flex: 1 0;
    max-width: 100%;
    margin-right: 0.5rem;
    min-height: 52px;
    height: auto !important;
  }

  &__price {
    flex: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
}
</style>
