<template>
  <div>
    <h1 class="name">{{ event.name }}</h1>
    <h1 class="date">{{ $d(Date.parse(event.startDate), 'long') }}</h1>
    <v-carousel
      class="hidden-lg-and-up"
      :show-arrows="event.coverImages && event.coverImages.length > 1"
      :hide-delimiters="!event.coverImages || event.coverImages.length <= 1"
    >
      <v-carousel-item v-for="(item, index) in event.coverImages" :key="index" :src="item" />
    </v-carousel>
    <p class="description pt-2 px-8 px-lg-0">
      <read-more
        :less="$t('globals.readLess')"
        :more="$t('globals.readMore')"
        :text="event.description"
        v-if="event.description"
      />
    </p>
  </div>
</template>

<script>
import ReadMore from '@/components/read-more/ReadMore.vue';

export default {
  name: 'EventInfo',
  components: { ReadMore },
  props: ['event'],
};
</script>

<style lang="scss" scoped>
@import './src/styles/core/variables';

.description {
  white-space: pre-line;
}

@include carousel-fix(contain);

@include breakpoint(medium) {
  img {
    display: none;
  }
}
</style>
