<template>
  <div>
    <v-carousel
      class="hidden-md-and-down"
      :show-arrows="hasMoreThanOneImage"
      :hide-delimiters="hasNoOrOneImage"
    >
      <v-carousel-item v-for="item in getImages" :key="item.id" :src="item" />
    </v-carousel>
  </div>
</template>
<script>
export default {
  name: 'EventImagesCarousel',
  props: ['event'],
  computed: {
    hasNoOrOneImage() {
      return !this.event.coverImages || this.event.coverImages.length <= 1;
    },
    hasMoreThanOneImage() {
      return this.event.coverImages && this.event.coverImages.length > 1;
    },
    getImages() {
      return this.event.coverImages;
    },
  },
};
</script>
