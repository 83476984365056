<template>
  <div id="buy-ticket-summary" v-if="event">
    <v-container class="px-0 py-0" fluid>
      <v-row no-gutters>
        <v-col cols="1" lg="1" />

        <v-col class="px-lg-16" cols="10" lg="6">
          <div v-if="eventIsLoaded">
            <event-info :event="event" />

            <command-summary
              :error="errorMessage"
              :eventName="event.name"
              :is-loading="isSending"
              :newsletter="newsletter"
              :receiveInfo="receiveInfo"
              :ticket="selectedTicket"
              @newsletterChange="setNewsletter"
              @pay-command="payTicket"
              @receiveInfoChange="setReceiveInfo"
            />
          </div>
          <loading v-else />
        </v-col>

        <v-col cols="1" lg="5">
          <event-images-carousel :event="event" />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import Loading from '@/components/loading/Loading.vue';
import { DISPLAY_MESSAGE, MESSAGE_MODULE } from '@/stores/shared/actions/message/message.actions';
import { APP_EVENT_MODULE } from '@/stores/umanize-app/actions/event/app-event.actions';
import CommandSummary from '@/components/ticketing/command-summary/CommandSummary.vue';
import EventImagesCarousel from '@/components/ticketing/event-images-carousel/EventImagesCarousel.vue';

import EventInfo from '@/components/ticketing/event-info/EventInfo.vue';
import { APP_USER_MODULE } from '@/stores/umanize-app/actions/user/app-user.actions';
import {
  APP_TICKET_MODULE,
  SET_TICKET_NEWSLETTER,
  SET_TICKET_RECEIVE_INFO,
} from '@/stores/umanize-app/actions/ticket/app-ticket.actions';
import { FETCH_MY_EVENTS } from '@/stores/agnostic/actions/event/agnostic-event.actions';

export default {
  name: 'BuyTicketSummary',
  components: {
    Loading,
    CommandSummary,
    EventInfo,
    EventImagesCarousel,
  },
  computed: {
    ...mapGetters(APP_TICKET_MODULE, [
      'newsletter',
      'receiveInfo',
      'tickets',
      'selectedTicket',
      'isSending',
      'isLoaded',
      'errorMessage',
      'errorType',
    ]),
    ...mapGetters(APP_USER_MODULE, ['loggedInUser']),
    ...mapGetters(APP_EVENT_MODULE, ['event', 'myEvents', 'eventIsLoaded']),
    hasNoOrOneImage() {
      return !this.event.coverImages || this.event.coverImages.length <= 1;
    },
    hasMoreThanOneImage() {
      return this.event.coverImages && this.event.coverImages.length > 1;
    },
  },
  methods: {
    ...mapActions(MESSAGE_MODULE, [DISPLAY_MESSAGE]),
    ...mapActions(APP_EVENT_MODULE, [FETCH_MY_EVENTS]),
    ...mapActions(APP_TICKET_MODULE, [SET_TICKET_NEWSLETTER, SET_TICKET_RECEIVE_INFO]),
    setNewsletter(value) {
      this[SET_TICKET_NEWSLETTER](value);
    },
    setReceiveInfo(value) {
      this[SET_TICKET_RECEIVE_INFO](value);
    },
    setPayment(payment) {
      this.payment = payment;
    },
    payTicket() {
      const { id } = this.event;
      this.$router.push(`/events/${id}/pay`).catch((failure) => {
        console.debug(failure);
      });
    },
  },
  beforeMount() {
    if (!this.selectedTicket) {
      this.$router.push({ name: 'Buy', params: { eventId: this.event.id } });
    }
  },
};
</script>
<style lang="scss" scoped>
@import 'src/styles/core/variables';
@include carousel-fix(contain);
</style>
